import apiClient from '@/services/axios'

export const getPriceRange = ({ business_id, product_id }) => {
  return apiClient({
    method: 'get',
    url: `/price/query/${product_id}`,
    headers: {
      'Business-Id': business_id,
    },
    params: {
      business_id,
    },
  })
}

export const getPriceFormula = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: '/price/query/price-formulas',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getPriceFormulaDetail = ({ business_id, formula_id }) => {
  return apiClient({
    method: 'get',
    url: `price/query/price-formulas/${formula_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}
export const getVariableDetail = ({ id, params }) => {
  return apiClient({
    method: 'get',
    url: `/price/query/v2/variables/${id}/detail`,
    params,
  })
}

export const getPriceVariables = ({ type, params }) => {
  return apiClient({
    method: 'get',
    url: `/price/query/v2/variables/${type}`,
    params,
  })
}

export const getPriceCalculation = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: '/price/query/v2/calculation',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const isUsedVariable = ({ type, params }) => {
  return apiClient({
    method: 'get',
    url: `/price/query/v2/variables/used/${type}`,
    params,
  })
}

export const isUsedFormula = (params) => {
  return apiClient({
    method: 'get',
    url: '/price/query/price-formulas/used',
    params,
  })
}

export const saveProductPrice = (data) => {
  return apiClient({
    method: 'post',
    url: `/price/cmd/product/${data.product_id}`,
    data,
    params: {
      business_id: data.business_id,
    },
  })
}

export const crudPriceVariable = ({ method, id = undefined, data }) => {
  const path = id ? `/${id}` : ''
  return apiClient({
    method,
    url: `/price/cmd/variables${path}`,
    data,
  })
}

export const crudPriceFormula = ({ method, id = undefined, data }) => {
  const path = id ? `/${id}` : ''
  return apiClient({
    method,
    url: `/price/cmd/v2/price-formulas${path}`,
    data,
  })
}

export const deleteVariable = ({ id, data }) => {
  return apiClient({
    method: 'delete',
    url: `/price/cmd/variables/${id}`,
    data,
  })
}

export const deleteFormula = ({ id, data }) => {
  return apiClient({
    method: 'delete',
    url: `/price/cmd/v2/price-formulas/${id}`,
    data,
  })
}